.category-modal{
    padding: 20px;
    margin: auto;
    display:flex;
    align-items:center;
    justify-content:center;
}
/* .action-menu-icon .category-action-menu-icon{
    font-size:30px !important
} */
.category-table-main tr td:last-child button.MuiIconButton-sizeMedium{
    padding:20px 3.5px 20px 3.5px !important;
}
.category-table-main tr td{
    padding: 0px 0px 0px 0px  !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover{
    background-color: rgba(0, 0, 0, 0) !important;
}

.category-image{
    height:60px;
    width:80px;
    display:table-column
}


.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #003d90 !important;
}

.modal {
    position: fixed;
    padding: 20px;
}

/* .table-container {
    max-height: 410px
} */
.action-btn-icon button{
    min-width: auto;
}
.admin-main .action-btn-icon svg {
    width: 22px;
    height: 22px;
}



/* .table_scroll{
    width: 100%;
    overflow:scroll
 } */
/* .user-list-table .table-head th:nth-child(1),
.user-list-table .table-body td:nth-child(1){
width: 10% !important;
}
.user-list-table .table-head th:nth-child(2),
.user-list-table .table-body td:nth-child(2){
width: 20% !important;
}
.user-list-table .table-head th:nth-child(3),
.user-list-table .table-body td:nth-child(3){
width: 10% !important;
}
.user-list-table .table-head th:nth-child(4),
.user-list-table .table-body td:nth-child(4){
width: 10% !important;
}
.user-list-table .table-head th:nth-child(5),
.user-list-table .table-body td:nth-child(5){
width: 10%;
}
.user-list-table .table-head th:nth-child(6),
.user-list-table .table-body td:nth-child(6){
width: 10% !important;
}
.user-list-table .table-head th:nth-child(7),
.user-list-table .table-body td:nth-child(7){
width: 10% !important;
}
.user-list-table .table-head th:nth-child(8),
.user-list-table .table-body td:nth-child(8){
width: 10% !important;
}
.user-list-table .table-head th:nth-child(9),
.user-list-table .table-body td:nth-child(9){
width: 10% !important;
} */


.common-form-box .form-back-btn {
    text-align: center;
}
/* .common-form-box .form-back-btn .back-btn {
    background-color: #005c8a;
    border-radius: 0;   
} */
.form-back-btn .back-btn {
    background-color: #005c8a;
    border-radius: 0;   
    font-family: "poppins-semibold";
}
@media (max-width:768px) {
    .table-filter-cont .searchbar {
        width: auto !important;
    }

    .table-filter-cont {
        justify-content: center !important;
        gap: 12px;
        margin-bottom: 10px !important;
    }

    .auth-modal-inner-main.login-with-modal-inner-main {
        width: 100% !important;
        padding: 16px !important;
        background: transparent;
        box-shadow: none;
        max-height: none !important;
    }
}

@media (max-width:575px) {
    .admin-main .dashboard-common-header {
        justify-content: center;
    }
    .reg-input {
        width:100%;
    }
}

@media (max-width:480px) {
    .d-flex.align-items-center.res-set-search.MuiBox-root.css-0 {
        justify-content: center;
    }


}
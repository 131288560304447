/* .admin-main .dashboard-main {
    padding: 20px 16px;
} */

.admin-main .dashboard-main .total-counter-box {
    display: flex;
    /* grid-template-columns: repeat(5, 1fr); */
    gap: 16px;
    margin-bottom: 30px;
}

.admin-main .dashboard-main .total-counter-box .counter-item-left {
    text-align: start;
}

.admin-main .dashboard-main .total-counter-box .total-counter-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 10px 15px;
    border-radius: var(--radius6);
    box-shadow: var(--shadow-sm);
    background-color: #fff;
}

.admin-main .dashboard-main .total-counter-item .counter-title {
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary);
    margin-bottom: 5px;
    letter-spacing: 0.5px;
    font-family: "poppins-semibold";
}

.admin-main .dashboard-main .total-counter-item .counter-number {
    font-size: 18px;
    line-height: 24px;
    font-family: "poppins-semibold";
}

.admin-main .dashboard-main .total-counter-item .counter-item-right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
}

.admin-main .dashboard-main .counter-item-right svg {
    width: 30px;
    height: 30px;
}

.total-counter-box .total-counter-item {
    background: #ffffff;
    box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 40px 25px 20px;
    height: 100%;
}

.total-counter-box .total-counter-item:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    content: "";
}

.total-counter-item:nth-child(1):after {
    background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}

.total-counter-item:nth-child(2):after {
    background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}

.total-counter-item:nth-child(3):after {
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.total-counter-item:nth-child(4):after {
    background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}

.total-counter-item:nth-child(5):after {
    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(237, 3, 32, 0.87) 20.8%, rgba(242, 121, 1, 0.84) 74.4%);
}

/* New Filter CSS */
/* .filter-cont {
    display: flex;
    justify-content: start;
    margin-bottom: 30px;
} */

.filter-cont .filter-table-cont,
.filter-cont .filter-data-cont {
    margin-right: 10px;
    min-width: 120px;
    height: 36px;
    background-color: var(--white);
    border-radius: 0;
    font-size: 14px;
    font-family: "poppins-medium";
    color: #005c8a;
    border: 0 !important;
}

/* End Filter CSS */
.res-set-search{
    gap: 10px;
    flex-wrap: wrap;
}
.res-set-search button{
    background-color:#005c8a;
    border-radius: 0;
    font-family: "poppins-semibold";
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #005c8a !important;
}

.Idealeaft-view-inpt-main {
  width: 100%;
  margin-bottom: 15px;
}

.Idealeaft-view-inpt-main .title {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: "poppins-semibold";
}

.Idealeaft-view-inpt-main .not-found-para {
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 16px;
  display: inline-block;
}

.Idealeaft-view-inpt-main:last-child {
  margin-left: 0px;
}

.Idealeaft-view-inpt-main .textarea-control {
  min-height: 85px;
  resize: none;
  width: 100%;
  border: 1px solid #0000003b;
  padding: 15px;
  border-radius: 6px;
}

.modal-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-btn-flex .modal-btn {
  background: #f1c40fad;

  color: #000;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: "poppins-medium";
  text-transform: capitalize;
  border: 1px solid gray;
  padding: 10px 20px;
}

.modal-contained-leaf-wrap {
  padding: 15px;
}

.leaf-profile-img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.leaf-profile-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.Idealeaft-view-inpt-main .cat-btn {
  background-color: #dae0e3;
  margin-left: 10px;
}

.Idealeaft-view-inpt-main .cat-subcat-view-btn-main {
  display: flex;
}


.modal .profile-zoom-modal-inner {
  min-width: 500px;
}

.modal .leaf-modal-inner-main {
  min-width: 500px;
}

.invalid-url {
  height: 50px;
  max-height: 50px;
  line-height: 50px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #707070;
  border-radius: 10px;
  color: black;
  background-color: white;
}

.check-img-data {
  display: none;
}

.info-card {
  /* width: 100vh; */
  /* margin-left: 30px; */
  background-color: #f0f3f8ed;
  /* border: 1px solid #DFE0E1; */
  border-radius: 10px !important;
  box-shadow: 4px 4px 6px #dfe0e1;
  display: flex;
  justify-content: space-evenly;
  /* padding: 15px 0px 2px 0px; */
}

.mini-card-main {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  /* border: 2px solid red; */
  width: 100%;
}

.card-right-content {
  /* width:100vh; */
  margin-bottom: 15px;
  background-color: #ffffffed;
  box-shadow: 4px 4px 6px #dfe0e1;
}

.mini-card-img img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.card-right-content-box {
  margin-right: 20px;
  padding: 20px 0px 20px 0px !important;
  width: 60%;
}

.card-graph-content-inner .card-total {
  font-size: 10px;
  line-height: 16px;
  color: #005c8a;
  font-family: "poppins-semibold";
  margin-bottom: 0;
}

.card-graph-content-inner .card-price {
  font-size: 16px;
  line-height: 25px;
  color: #005c8a;
  font-family: "poppins-semibold";
}

.detail-content {
  margin-left: 20px;
  margin-right: 30px;
}

.card-main {
  background: #f0f3f8;
  padding: 30px;
  box-shadow: 0px 3px 30px #0000000d;
  border-radius: 10px;
}

.profile-card-main {
  width: 1084px;
}

/* .input-design-div .textarea-control {
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-regular";
  color:#122b45;
  overflow: hidden;
  height: 80px !important;
  width: 100%;
  border-radius: 7px;
  border: 1px solid #ccc;
}
.description-detail {
  margin-top: -50px;
} */

.Idealeaft-view-inpt-main .textarea-control {
  min-height: 85px;
  resize: none;
  width: 100%;
  border: 1px solid #0000003b;
  padding: 15px;
  border-radius: 0px;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-regular";
  color: #122b45;
}


.profile-zoom-modal .profile-zoom-modal-inner .leaf-profile-zoom-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.modal .leaf-modal-inner-main {
  min-width: 500px;
}

.likecard {
  background-color: rgb(201, 204, 219);
}

.bck-ar {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.progress-circle {
  height: 200px;
  width: 200px;
}

/* .model-zoom-img{
  display: flex;
  justify-content: center;
} */
.common-modal-loader {
  display: flex;
  justify-content: center;
}


.leaf-categ-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.user-detail-subtitle.leaf-detail {
  display: flex;
  flex-wrap: 100%;
}

.list.modal-total-views .image-main-wrapper {
  margin-bottom: 9px;
  gap: 9px 18px;
  flex-wrap: wrap;
}

.list.modal-total-views .image-main-wrapper::after {
  display: block;
  content: '';
  width: 100%;
  background: #ccc;
  height: 1px;
}

.list.modal-total-views .image-main-wrapper .image-wrape {
  height: 66px;
  width: 66px;
  border-radius: 18px;
}

.list.modal-total-views .image-main-wrapper .image-wrape img {
  height: 100%;
  width: 100%;
  border-radius: 18px;
  transform: none;
  margin-right: 0;
}
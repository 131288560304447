@media (max-width:1367px) {
    .admin-nft-detail {
        columns: 2;
    }

    .nft-detail-img .user-detail-img {
        width: 90px;
        height: 90px;
    }
}

@media (max-width:1080px) {
    .nft-detail-img .user-detail-img {
        width: 126px;
        height: 126px;
    }
}

@media (max-width:768px) {
    .admin-nft-detail {
        columns: 1;
    }

    .nft-detail-img .user-detail-img {
        width: 90px;
        height: 90px;
    }
}

@media (max-width:375px) {
    .admin-nft-detail{
        padding: 2px;
    }
}
.admin-main .admin-main-bg {
    background-image: url('../../../../assets/images/Jpg/companyNewLogo.jpg');
    height: 100vh;
    background-size: cover;
    background-position: center;
    width: 100%;
}


.admin-main .admin-main-bg:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.admin-main .auth-main {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    gap: 30px;
    width: 100%;
    position: relative;
    z-index: 1;
}

.admin-main .auth-main .auth-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    padding: 50px 15px;
}

.admin-main .auth-main .auth-left .company-logo {
    width: 100%;
    max-width: 300px;
    height: auto;
}

.admin-main .auth-main .auth-right {
    width: 100%;
    overflow: auto;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
}

.admin-main .auth-main .auth-common-box {
    padding: 20px;
    border-radius: var(--radius6);
    box-shadow: var(--shadow-sm);
    background-color: #fff;
    width: 100%;
    max-width: 390px;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
}


.admin-main .auth-main .auth-header {
    margin-bottom: 16px;
}

.admin-main .auth-main .auth-header .auth-title {
    font-size: 28px;
    line-height: 30px;
    font-family: "poppins-semibold";
    margin-bottom: 16px;
    text-align: center;
    color: var(--white);
}

.admin-main .auth-main .auth-header .auth-title.auth-logo-title {
    font-size: 24px;
}

.admin-main .auth-main .auth-header .auth-subtitle {
    color: #989898;
    font-size: 14px;
    line-height: 18px;
}

.admin-main .auth-link {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    line-height: 18px;
    font-family: "poppins-medium";
    color: var(--primary);
}

.admin-main .auth-link.forgot-password {
    justify-content: flex-start;
}

.admin-main .auth-link.forgot-password p {
    color: #fff;
}

.admin-main .auth-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.admin-main .auth-btn-box button {
    background-color: var(--primary);
    margin: 0 auto;
}

.admin-main .form-group .form-input.otp-input-box {
    justify-content: space-between;
    gap: 15px;
}

.admin-main .form-group .form-input.otp-input-box input {
    width: 100%;
    border: 2px solid var(--secondary);
    text-align: center;
    font-family: "poppins-regular";
}

.admin-main .form-group .form-input.otp-input-box input:focus,
.admin-main .form-group .form-input.otp-input-box input:focus-visible {
    border: 2px solid var(--primary);
    outline: none;
}
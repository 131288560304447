:root {
    --black: #000;
    --white: #fff;
    --border-color: #c1c1c1;
    --background: #f3f7fc;
    --primary: #005c8a;
    --secondary: #7C8363;
    --radius12: 12px;
    --radius6: 6px;
    --shadow-sm: 0 0 3px 3px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 0 3px 3px rgba(0, 0, 0, 0.1);
}

a:hover,
a:focus,
input,
textarea {
    text-decoration: none;
    outline: none;
}

.p-0 {
    padding: 0px !important;
}

.pt-0 {
    padding-top: 0px;
}

.pt-10 {
    padding-top: 10px;
}

.pb-0 {
    padding-bottom: 0px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-5 {
    margin-top: 5px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mt-10 {
    margin-top: 15px;
}

.mr-10 {
    margin-right: 10px;
}
.ml-5{
    margin-left: 5px !important;
}
.mt-15 {
    margin-top: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.w-100 {
    width: 100%;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}
.max-w-150{
    max-width: 150px;
}
.overflow-auto {
    overflow: auto;
}

.text-end {
    text-align: end;
}

.table-box.table_scroll {
    max-width: calc(100vw - 80px);
}


.searchbar {
    border: 1px solid #d4d9e1;
    width: 200px !important;
    height: 36px;
    line-height: 31px;
    border-radius: 0px !important;
    margin-right: 0px !important;
    background-color: #fff !important;
}

.searchbar input::placeholder {
    color: #919cac;
    opacity: 1;
}

.searchbar input {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 14px 6px 6px !important;
    font-family: "poppins-regular";
}

.admin-input-design.input-placeholder.searchbar {
    width: 100% !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
    border-radius: 0 !important;

}

/* Table CSS */
.episap-common-box {
    background-color: var(--background);
    padding: 20px;
    /* border: 1px solid #DFE0E1; */
    border-radius: var(--radius6);
}

.episap-common-box-table {
    background-color: var(--background);
    padding: 0px !important;

    border-radius: 10px;
}

.table-wrape .episap-common-box {
    padding: 15px;
}

.user-detail-popup.episap-common-box {
    background-color: var(--background);
    padding: 20px;
    border-radius: 10px;
    width: 600px;
}

.user-detail-popup.episap-common-box .modal-body {
    max-height: 80vh;
    overflow: auto;
}


.episap-common-box .episap-table-main {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.episap-common-box .episap-table-main tr th {
    background: #b6dff9;
    font-family: "poppins-semibold";
    color: #111;
    font-size: 13px;
    padding: 9px;
    white-space: nowrap;
    top: auto;
}


.episap-common-box .episap-table-main tr td {
    color: #122B45;
    font-size: 12px;
    padding: 9px;
    font-family: "poppins-regular";
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.episap-common-box .episap-table-main tr td:first-child {
    padding-left: 20px;
}

/* End Table CSS */

/* Pagination */
.pagination-design nav {
    display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
    color: #003d90;
}

.pagination-design nav ul li button {
    min-width: 26px;
    height: 26px;
    border-radius: 0;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
    min-width: 26px;
    height: 26px;
    background: transparent;
    margin: 0;
    font-family: "poppins-medium";
    color: #005c8a;
    border-radius: 0;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
    background: #005c8a;
    color: #f8e8c4;
}

.pagination-design {
    margin-top: 15px;
}


/* End Pagination */


.title-heading {
    /* display: flex;
    justify-content: space-between; */
    width: 100%;
    /* align-items: center; */
    margin-bottom: 10px;
}

.table-filter-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
}


.episap-common-box .page-title {
    margin-bottom: 8px;
}

.episap-common-box .page-title.mb-0 {
    margin-bottom: 0;
}

.dashboard-filter {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

/* Common Form Box */
.common-form-box {
    box-shadow: var(--shadow-md);
    padding: 16px;
    background-color: #f9f9f9;
}

.css-1ujnqem-MuiTabs-root.admin-tabs-main {
    min-height: 36px;
    border-bottom: 1px solid var(--primary);
    position: relative;
}

.admin-tabs-main .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 8px 16px;
    min-height: 36px;
    font-family: "poppins-semibold";
}

.admin-tabs-main .admin-tab.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    background-color: var(--primary);
    color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: "poppins-semibold";
}

.admin-tabs-main .css-1aquho2-MuiTabs-indicator {
    background-color: var(--primary);
}

.page-title.page-sub-title {
    font-size: 14px;
    font-family: "poppins-semibold";
    color: var(--primary);
}

.page-title.page-sub-title.mb-0 {
    margin-bottom: 0px;
}

.episap-modal-box {
    background-color: var(--background);
    min-width: 420px;
    max-width: 420px;
    border-radius: 8px;
}

.episap-modal-box .admin-design-div {
    display: block;
}

.episap-modal-box .reg-input {
    width: 100%;
    padding: 0px 16px;
    margin-bottom: 8px;
}

.episap-modal-box .reg-input:first-child {
    padding-top: 16px;
}

.episap-modal-box .episap-modal-header {
    background-color: var(--primary);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.edit-modal-circle-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
}

.episap-modal-box .input-design-div.admin-design-div {
    padding: 20px;
}

.episap-modal-box .episap-modal-header .title-header-flex {
    margin-bottom: 0;
}

.episap-modal-box .episap-modal-header .title-header-flex .title-main .page-title {
    color: #fff;
    font-size: 16px;
}

.episap-modal-box .user-circle-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    cursor: pointer;
    display: flex;
    /* margin: auto;
      display: flex; */
}

.profile-img {
    width: 86px;
    height: 86px;
    object-fit: cover;
    border-radius: 8px;
    display: flex;
    margin: auto auto 15px auto !important;
    border: 1px solid rgba(224, 224, 224, 1);
    box-shadow: var(--shadow-sm);
}

.auth-modal-inner-main.login-with-modal-inner-main.MuiBox-root.css-p1ak4w {
    padding: 0;
    max-height: 500px;
    width: 420px;
    border-radius: 100%;
}

.episap-modal-box .register-all-inputs {
    overflow: auto;
    max-height: 360px;
}

.episap-modal-box .title-main .page-sub-title {
    color: #c1c1c1;
}

.episap-modal-box .page-sub-title.mb-5 {
    margin-bottom: 0px;
    color: #005c8a;
    padding: 8px 16px 8px 16px;
}

.css-k008qs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-back-btn.mb-15 {
    text-align: end;
}

.episap-common-box .episap-table-main tr td.name-td {
    max-width: 100px;
}

.input-textarea textarea {
    border: 1px solid #d4d9e1;
    width: 100% !important;
    height: 80px !important;
    line-height: normal;
    padding: 8px;
    border-radius: 0px !important;
    margin-right: 0px !important;
    background-color: #fff !important;
    resize: none;
    font-size: 14px;
    font-family: "poppins-regular";
}

.admin-input-design {
    border-radius: 0px;
}

.tag-img-remove {
    display: none;
}

.logo-name {
    max-width: 180px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.admin-tabs-main.css-orq8zk {
    min-height: auto;
    border-bottom: 1px solid var(--primary);
}

.admin-tabs-main .css-ttwr4n {
    background-color: var(--primary);
}

.admin-tabs-main .css-1q2h7u5 {
    padding: 8px 16px;
    min-height: 36px;
    font-family: "poppins-semibold";
}


.admin-tabs-main .css-1q2h7u5.Mui-selected {
    padding: 8px 16px;
    min-height: 36px;
}

.admin-tabs-main .admin-tab.css-1q2h7u5.Mui-selected {
    color: rgb(25, 118, 210);
    background-color: var(--primary);
    color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-family: "poppins-semibold";
}

.notification-main-box {
    border: 1px solid #005c8a;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
}

.notification-box {
    display: grid;
    grid-template-columns: 0.2fr 5fr 0.5fr;
    gap: 16px;
    align-items: center;
}

.notification-img{
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.notification-img img{
    height: 100%;
    width: 100%;

}

.notification-text{
    font-size: 16px;
    font-weight: bold;
}

.notification-sub-text,.notification-time{
    font-size: 14px;
}



.view-icon{
    color: #003d90 ;
    /* margin-top: 6px; */
    cursor: pointer;
}


/* .nft--owner-detail{
padding: 30px 10px;
} */



.nft-heading-box .nft-heading{
    color: #003d90;
    font-size: 19px;
    line-height: 20px;
    font-family: "poppins-bold";
}
.nft-heading-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.nft-heading-box .nft-creator-heading{
    color: #003d90;
    font-size: 19px;
    line-height: 20px;
    font-family: "poppins-bold";
    margin-left: 20px ;
    margin-top: -10px;
}
.user-image-wrape .nft-image{
margin: 15px 0px;
}

.profile-detail .nft-category{
    color: #204e7e;
    font-size: 13px;
    line-height: 15px;
    font-family: 'poppins-regular';
    margin-bottom: 23px;
}

.main-image-nft{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nft-image label{
    color: #122B45;
    font-size: 14px;
    line-height: 15px;
    font-family: 'poppins-semibold';
    margin-bottom: 5px;
    display: block;
}


.nft_tabel thead tr th  {
   width: 15%;

}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(1), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(1) {
    width: 2%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(2), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(2) {
    width: 4%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(3), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(3) {
    width: 6%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(4), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(4) {
    width: 4%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(5), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(5) {
    width: 4%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(6), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(6) {
    width: 4%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(7), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(7) {
    width: 6%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(8), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(8) {
    width: 4%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(9), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(9) {
    width: 4%;
  }

  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(10), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(10) {
    width: 4%;
  }
  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(11), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(11) {
    width: 4%;
  }
  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(12), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(12) {
    width: 4%;
  }
  .cus-table-scroll .master-warden-nft-table .table-th:nth-child(13), .cus-table-scroll .master-warden-nft-table .table-td:nth-child(13) {
    width: 3%;
  }
  .cus-table-scroll .master-warden-nft-table {
    min-width: 1300px;
  }

  .warning-icon{
    width: 25px;
    height: 25px;
  }
.category-modal-header {
  justify-content: center !important;
}

.admin-design-div {
  display: flex;
}

.episap-modal-cont {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  max-width: 380px;
}

.episap-modal-cont .admin-input-design {
  margin-bottom: 10px;
}

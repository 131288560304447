/* @media (max-width: 1025px) {
    .table-box.table_scroll {
        max-width: calc(100vw - 80px);
    }
} */
@media (max-width:768px) {
    .episap-modal-box {
        min-width: auto;
    }
    .leaf-categ-box {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 575px) {
    .episap-common-box.tab-mobile-pd {
        padding: 0;
    }

    .table-wrape {
        padding: 0px;
        margin-top: 10px
    }

    .title-header-flex {
        justify-content: center;
        align-items: center;
    }

    .episap-common-box .episap-table-main tr td img {
        height: 40px !important;
        width: 40px !important;
    }

    .form-back-btn.MuiBox-root.css-0 {
        text-align: end;
        width: 100%;
    }

    .episap-common-box .page-title {

        margin-right: 8px;
    }

    .leaf-categ-box {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 575px) {}
.Idealeaft-view-inpt-main {
  width: 100%;
  margin-bottom: 15px;
}
.Idealeaft-view-inpt-main .title {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  display: inline-block;
  font-family: "poppins-semibold";
}
.Idealeaft-view-inpt-main .not-found-para {
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 16px;
  display: inline-block;
}
.Idealeaft-view-inpt-main:last-child {
  margin-left: 0px;
}
.Idealeaft-view-inpt-main .textarea-control {
  min-height: 85px;
  resize: none;
  width: 100%;
  border: 1px solid #0000003b;
  padding: 15px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-regular";
  color:#122b45;
}
.modal-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.modal-btn-flex .modal-btn {
  background: #f1c40fad;

  color: #000;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-family: "poppins-semibold";
  text-transform: capitalize;
  border: 1px solid gray;
  padding: 10px 20px;
}
.modal-contained-leaf-wrap {
  padding: 15px;
}
.leaf-profile-img {
  height: 120px;
  cursor: pointer;
  width: 120px;
  border-radius: 50%;
}
.leaf-profile-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.Idealeaft-view-inpt-main .cat-btn {
  background-color: #dae0e3;
  margin-left: 10px;
}
.Idealeaft-view-inpt-main .cat-subcat-view-btn-main {
  display: flex;
}
.profile-zoom-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
}


 .invalid-url {
  height: 50px;
  max-height: 50px;
  line-height: 50px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #707070;
  border-radius: 10px;
  color: black;
  background-color:white;
}
.check-img-data{
  display: none;
}
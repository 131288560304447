* {
  outline: none;
}

.user-detail-section {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #a5c7f5;
  display: flex;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.left-wrape {
  width: 280px;
  border-right: 1px solid #a5c7f5;
}

.profile-wrape {
  width: 100%;
  height: 150px;
  position: relative;
  background: #003d90;
}

.profile-wrape .image-wrape {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  bottom: -40px;
  left: 50%;
  overflow: hidden;
  border: 6px solid #ffffff;
  margin-left: -40px;
}

.mini-card .owner-image-wrape {
  /* position: absolute; */
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  /* bottom: -40px;
    left: 50%; */
  overflow: hidden;
  /* border: 6px solid #ffffff; */
  /* margin-left: -40px; */
  display: flex;
  align-items: center;
}

.profile-wrape .image-wrape img {
  transform: scale(1.15);
}

.detail {
  padding: 60px 15px;
}

.detail label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "poppins-semibold";
  margin-bottom: 5px;
  display: block;
}

.detail p {
  color: #204e7e;
  font-size: 13px;
  line-height: 15px;
  font-family: "poppins-regular";
  margin-bottom: 23px;
}

.right-wrape {
  display: flex;
  width: calc(100% - 280px);
  flex-wrap: wrap;
}

.stat-section {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #a5c7f5;
  max-height: 105px;
}

.stat-section .mini-card {
  width: calc(45% / 2);
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #a5c7f5;
  display: flex;
  margin: 0;
  height: auto;
  flex-basis: inherit;
  max-width: inherit;
  justify-content: center;
  cursor: pointer;
}

.stat-section .icon-wrape {
  width: 48px;
  height: 48px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 5px;
  overflow: hidden;
  margin-right: 10px;
}

.legend {
  color: #585d65;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 2px;
  font-family: "poppins-regular";
}

.stats {
  color: #003d90;
  font-size: 20px;
  line-height: 1.5;
  font-family: "poppins-semibold";
  word-break: break-word;
}

.stat-section .big-card {
  padding: 10px 0;
  overflow: hidden;
}

.stat-section .big-card .detail-wape {
  padding: 0 15px;
}

.community-wrape {
  overflow: auto;
  white-space: nowrap;
}

.community-wrape .community {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border: 1px solid #b8b8b8;
  margin: 5px 0 5px 15px;
  transition: all 0.15s linear 0.15s;
  display: inline-block;
  cursor: pointer;
}

.community-wrape .community:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.15s linear 0.15s;
  border-color: #ffffff;
}

.table-wrape {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.table-wrape .barge-common-box {
  padding: 15px;
}

.table-wrape .title-header-flex {
  align-items: center;
}

.table-wrape .search {
  margin-right: 0 !important;
}

.user-detail-popup.barge-common-box {
  background-color: #f0f3f8;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
}

.user-detail-popup .title-main .page-title {
  color: #003d90;
  font-size: 19px;
  line-height: 20px;
  font-family: "poppins-semibold";
  white-space: nowrap;
}

.user-detail-popup.barge-common-box .modal-body {
  max-height: 80vh;
  overflow: auto;
}

.follow-list-section {
  max-height: 80vh;
  overflow: auto;
}

.community-popup .modal-body .follow-list-section {
  max-height: 45vh;
}

.user-detail-popup .list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding-top: 15px;
  margin-bottom: 10px;
  align-items: center;
}

.user-profile-view {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.image-main-wrapper {
  display: flex;
  align-items: center;
}

.user-detail-popup .list .detail-text {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 3px;
  font-family: "poppins-semibold";
}

.user-detail-popup .list .date-like-dislike-model {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "poppins-semibold";
}

.image-wrape {
  display: flex;
}

.image-wrape .img-wraper-img {
  height: 100%;
  /* margin-right: 15px; */
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.user-detail-popup .list .details-user-name {
  color: #7a6868;
  font-size: 12px;
  line-height: 15px;
  font-family: "poppins-regular";
}

.user-detail-popup .community-detail {
  display: flex;
  position: relative;
  background: #003d90;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.user-detail-popup .community-detail .image-wrape {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #f0f3f8;
  margin-right: 0;
  transition: all 0.15s linear 0.15s;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.user-detail-popup .community-detail .image-wrape:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.15s linear 0.15s;
  border-color: #ffffff;
}

.user-detail-popup .group-detail-wrape {
  width: 100%;
  text-align: center;
  padding: 0 15px 20px;
  color: #fff;
  margin-top: 40px;
}

.user-detail-popup .group-detail-wrape h6 {
  font-size: 20px;
}

.user-detail-popup .group-detail-wrape span {
  font-size: 14px;
  opacity: 0.7;
  font-family: "poppins-regular";
}

.user-detail-table-container {
  max-height: 300px;
}

/* .list .date-main-wrapper{
    right: 144px;
    position: absolute;
} */

.user-image-wrape .user-prof-img {
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  margin: 30px 0 80px 0;
}

.user-profile-wrapper {
  text-align: center;
  padding: 15px;
}

.profile-detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0;
}

.profile-detail p,
.profile-detail label {
  margin-bottom: 0;
}

.profile-detail:last-child {
  margin-bottom: 0px;
}

.profile-detail .description {
  width: 108px;
  word-break: break-word;
  text-align: left;
}

.profile-detail .owner-description {
  width: 360px;
  word-break: break-word;
  text-align: left;
}

.owner-detail-wrap {
  display: flex;
  border-bottom: 1px solid #a5c7f5;
  width: 100%;
  padding: 10px;
}

.owener-detail {
  flex-direction: column;
  border-bottom: 0;
}

.reply-box {
  margin-left: 40px;
}

.sub-reply-box {
  margin-left: 60px;
}

.comment-box {
  background-color: #dfe0e1;
  width: 100%;
  border-radius: 6px;
  padding: 6px 20px;
  margin-bottom: -7px;
}

.owner-image-wrape {
  margin-right: 20px;
  width: 100px;
  height: 100px;
}

.owner-image-wrape img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

/* .auth-alert-sucsess {
    background-color: #d1e7dd;
    border-color: #badbcc;
  }
  .auth-alert-box  .auth-alert-sucsess{
    color: #0f5132;
  } */
.comment-box .comment-text {
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  font-family: "poppins-regular";
  word-break: break-all;
}

.comment-box-wrap {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.user-image-box .user-image {
  margin-right: 5px;
  /* margin-bottom: 5px; */
}

.comment-box .user-name-box {
  display: flex;
  justify-content: space-between;
}

.user-name-box .user-name {
  font-size: 15px;
  font-family: "poppins-semibold";
}

.user-name-box .comment-date-text {
  font-size: 11px;
  font-family: "poppins-regular";
}

.user-image-box {
  display: flex;
  align-items: center;
}

.comment-image-box {
  width: 100px;
  height: 100px;
}

.comment-image-box img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.community-discussion-loading-box {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.community-discussion-loading-box .community-discussion-not-found {
  text-align: center;
  font-size: 14px !important;
  font-family: "poppins-medium" !important;
  color: gray;
}

.community img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.list.community-users{
    width: 100%;
    padding-top: 0;
    margin-bottom: 0px;
    border-top: 0px;
    border-bottom: 1px solid #d9d9d9;
}

.list.community-users.modal-total-views .image-main-wrapper{
    padding: 10px;
    gap: 0 18px;
    margin-bottom: 0;
    width: 100%;
    border-bottom: 0;
}


.list.community-users.modal-total-views .image-main-wrapper::after{
    display: none;
}
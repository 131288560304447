 .tree-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  max-width: 1400px;
}


.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}


.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.node__root > circle {
  fill: red;
  r: 30;
}

.node__branch > circle {
  fill:  #166fd4;
  r: 30;
}


.rd3t-tree-container .rd3t-grabbable {
  height: 600px;
}
 .tree-node-line{
  cursor: context-menu;
  stroke: #fff;
  
 }
 .main-svg-circle{
  stroke: #fff;
 }
 .rd3t-svg{
  cursor: zoom-in;
  
 } 
.title-header-flex.tree-head {
  display: flex;
  justify-content: center;
  width: 100%;
  /* align-items: center; */
  margin-bottom: 10px;
}
.bck-ar{
  height: 20px;
  width: 20px;
  cursor: pointer;
  object-fit: contain;
}
.tree-loader{
  width: 8%;
  /* background-color: red; */
}
.table-tree-loader{
  display: flex;
  justify-content: center;
}


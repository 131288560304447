* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: "poppins-semibold";
  src: url(../fonts/poppins-bold.ttf);
}

@font-face {
  font-family: "poppins-medium";
  src: url(../fonts/poppins-medium.ttf);
}

@font-face {
  font-family: "poppins-regular";
  src: url(../fonts/poppins-regular.ttf);
}

@font-face {
  font-family: "poppins-semibold";
  src: url(../fonts/poppins-semibold.ttf);
}

.d-flex,
.res-menu-icon {
  display: flex;
}

.d-block {
  display: block;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.h-100 {
  height: 100%;
}

.header-main {
  background: #003d90;
  padding: 4px 15px;
}

.position-relative {
  position: relative;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-ul {
  display: flex;
  color: #d4d9e1;
}

.header-ul li {
  font-family: "poppins-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
}

.logo-img {
  width: 178px;
  object-fit: cover;
  height: 38px;
}

.header-ul li a {
  text-decoration: none;
  color: #d4d9e1;
}

.p-15 {
  padding: 15px;
}

.title-header {
  display: flex;
  align-items: center;
}

.title-header-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: center; */
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.search {
  border: 1px solid #d4d9e1;
  width: 200px !important;
  height: 35px;
  line-height: 31px;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.search input::placeholder {
  color: #919cac;
  opacity: 1;
}

.search input {
  font-size: 12px;
  line-height: 15px;
  padding: 5px 14px 5px 5px !important;
  font-family: "poppins-regular";
}

.dashboard-text p,
.title-main .page-title {
  color: #003d90;
  font-size: 20px;
  line-height: 24px;
  font-family: "poppins-semibold";
}

.title-main .page-sub-title {
  color: #404040;
  font-size: 14px;
  line-height: 18px;
  font-family: "poppins-regular";
}

.common-button button,
.common-button button:hover {
  font-size: 12px;
  font-family: "poppins-semibold";
  padding: 7px 13px 6px 13px;
  line-height: 19px;
  box-shadow: 0px 3px 4px #ccc4b3;
  letter-spacing: 0px;
  border-radius: 4px;
  text-transform: capitalize;
}

.update_priofile_btn {
  align-items: center;
  display: flex;
  justify-content: center;
}

.grey-button button,
.grey-button button:hover {
  background-color: #005c8a;
  border-radius: 0;
  font-family: "poppins-semibold";
  color: #fff;
  margin-top: 15px;
}

.blue-button button,
.blue-button button:hover {
  background-color: #005c8a;
  border-radius: 0;
  font-family: "poppins-semibold";
  color: #fff;
}

.table-design-main tr th,
.table-design-main tr td {
  font-size: 12px;
  color: #122b45;
  /* line-height: 15px; */
}

.table-design-main tr th {
  font-family: "poppins-semibold";
  padding: 4px 9px !important;
}

.table-design-main tr td {
  font-family: "poppins-regular";
  padding: 5px 9px 10px 25px;
  border-bottom: 0 !important;
  /* border: 1px solid rgb(192, 255, 212); */
}

.table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.invoice-table tr td {
  background: #fff;
  display: table-cell;
  text-align: center;
}

.input-design-div .password {
  margin-top: 25px !important;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: #fff;
  border: 1px solid #dfe0e1;
  /* border-radius: 10px; */
  border-radius: 4px;
  height: 28px;
}

.input-design-div .input-placeholder input::placeholder {
  color: #122b45;
  font-family: "poppins-regular";
  /* font-size: 16px; */
  line-height: 20px;
}

.admin-input-design {
  width: 100%;
}

.admin-input-design {
  width: 100%;
}

.input-design-div.with-border input {
  /* height: 28px; */
  height: 35px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0 15px;
}

.input-design-div.with-border input,
.set-textarea-box-top .MuiInputBase-formControl {
  border: 1px solid #ccc;
  /* border-radius: 8px;  */
  border-radius: 0px;
  background-color: #fff;
  font-family: "poppins-regular";
  font-size: 14px;
}

.set-textarea-box-top fieldset {
  border: none !important;
}

.with-border .MuiFilledInput-underline {
  /* height: 28px !important; */
  height: 35px !important;
  font-size: 14px !important;
  letter-spacing: 0;
  background-color: #fff !important;
  border-radius: 0 !important;
}

.input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "poppins-semibold";
  padding-bottom: 8px !important;
  display: flex;
}

.line {
  height: 1px;
  background: #ebebeb;
}

.save-btn button {
  min-width: 110px;
}

.table-container {
  box-shadow: none !important;
}

.set-date .MuiStack-root {
  overflow: hidden;
  padding-top: 0px;
}

.set-date-picker .MuiInputBase-colorPrimary {
  background: #fff;
  border: 1px solid #00000033 !important;
  border-radius: 4px;
  width: 100%;
  /* height: 28px; */
  /* border-radius: 10px; */
  height: 35px;
  border-radius: 5px;
}

.set-date-picker fieldset {
  border-color: transparent !important;
}

.set-date-picker input {
  padding: 0px 14px !important;
  color: #120d26;
  font-size: 14px !important;
  line-height: 19px;
  font-family: "poppins-regular" !important;
  text-align: left;
  height: 28px;
}

.set-date-picker .MuiStack-root {
  padding-top: 0px !important;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
  width: 100%;
}

.accestimeicon {
  fill: #757575 !important;
}

.accestimeicon-img {
  position: absolute;
  top: 5px;
  right: 8px;
  display: flex;
}

.accestimeicon-img svg {
  height: 19px;
}

.set-date-picker svg {
  height: 19px;
}

.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #003d90;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  min-width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  font-family: "poppins-medium";
  color: #99a4b5;
}

.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: transparent;
  color: #003d90;
}

.pagination-design {
  margin-top: 15px;
}

.filter-btn button,
.filter-btn button:hover {
  max-width: 34px;
  height: 34px;
  min-width: 34px;
  background: #003d90;
  border: 1px solid #003d90;
}

/* 10-5 css */

.main-login {
  background-image: url("../images/Jpg/profile-dark-bg.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
}

.rightbox {
  background: #042443c4;
  height: 100vh;
  margin-left: auto;
  max-width: 50%;
  border-radius: 0px 0px 0px 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-login-main {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-set {
  padding: 30px 0px 0px 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-set img {
  height: 81px;
  width: 383px;
  object-fit: contain;
}

.logo-set2 img {
  /* height: 100px; */
  width: 120px;
  object-fit: contain;
}

.box-text p {
  color: white;
  font-size: 32px;
  line-height: 39px;
  font-family: "poppins-semibold";
}

.box-login-text p {
  font-family: "poppins-regular";
  font-size: 16px;
  color: white;
  line-height: 20px;
  margin: 15px 0px 40px 0px;
}

.main-box {
  max-width: 300px !important;
  width: 300px;
  flex: 0 0 300px;
}

.login-btn-main {
  margin: 30px 0px 20px;
}

.login-btn button {
  width: 100%;
  font-family: "poppins-semibold";
}

.orange-btn button,
.orange-btn button:hover,
.orange-btn .buttons,
.orange-btn .buttons:hover {
  /* background: #eaac35; */
  background: var(--primary);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  border-radius: 0px;
  box-shadow: none;
  min-height: 36px;
  font-family: "poppins-semibold";
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  width: auto;
  box-shadow: 0px 3px 4px #ccc4b3;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
}

.forgot p {
  margin: 0px !important;
  text-align: right !important;
}

.input-design-div .MuiInputBase-input {
  /* padding: 10px 16px !important; */
  padding: 3px 10px !important;
  color: #122b45;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 30px;
}

.tags-management {
  display: none !important;
}

.dashboard-text p {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "poppins-semibold";
  display: flex;
}

.main-box-dash {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 18.81%;
  min-height: 73px;
  padding: 14px 20px;
  width: calc(100% / 5);
  /* border: 1px solid #DFE0E1; */
  margin: 10px 7.5px 15px;
}

.text-main p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "poppins-medium";
  margin-bottom: 5px;
}

.number-text p {
  font-size: 16px;
  line-height: 20px;
  font-family: "poppins-semibold";
  color: #122b45;
}

.logo-dash img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dash-text {
  margin-left: 20px !important;
}

.dash-text-box-set {
  display: flex;
  align-items: center;
}

.set-dash-main {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  flex-wrap: wrap;
  margin-right: -7.5px;
}

.forgot a {
  text-decoration: none;
}

.barge-list-main-box {
  border-radius: 8px;
  /* / max-width: 323px; / */
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
  background: #f0f3f8;
}

.barge-set-dash-main {
  display: flex;

  /* / width: calc(50% + 15px); / */
  margin-left: -6.5px;
  flex-wrap: wrap;
  margin-right: -6.5px;
}

.brge-icon .brge-icon-set {
  width: 66px;
  height: 59px;
}

.set-progress {
  margin: 3px 0px 1px;
}

.set-progress span {
  width: 66px !important;
  height: 4px !important;
}

.set-progress .MuiLinearProgress-bar {
  background-color: #0d70d9 !important;
}

.brge-number .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "poppins-medium";
  color: #122b45;
}

.brge-img-set {
  padding: 12px 15px 10px;
  width: 30%;
  border-right: 1px solid #dfe0e1;
}

.barge-set-text-box {
  padding: 8px 8px 10px 15px;
  width: 100%;
  text-align: left;
}

.main-barge-box {
  display: flex;
}

.set-scheduled .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "poppins-regular";
  color: white;
  padding: 1px 6px;
}

.set-scheduled {
  border-radius: 30px;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  height: 12px;
}

.set-scheduled-text .text-name {
  font-size: 10px;
  line-height: 12px;
  font-family: "poppins-regular";
  color: #8d98ab;
}

.set-scheduled-text .text-span {
  font-size: 10px;
  line-height: 12px;
  font-family: "poppins-medium";
  color: #122b45;
}

.pt-5 {
  padding-top: 5px !important;
}

.set-scheduled-back {
  background: #51c6e5;
}

.set-available-back {
  background-color: #05e39c;
}

.main-bargebox {
  display: flex;
  width: 100%;
}

.todaytrip-list-main-box {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  /* / padding: 12px 15px 10px; / */
  /* / width: calc(100% / 2); / */
  /* border: 1px solid #DFE0E1; */
  /* / margin: 15px 6.5px 0px; / */
}

.dashboard-row-main .dashboard-item,
.dashboard-item {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent;
  text-align: center !important;
}

.background-ed {
  background: #fff;
  border-radius: 10px;
}

.today-trip-text {
  padding-top: 12px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.today-set-progress.set-progress .MuiLinearProgress-bar {
  background-color: #e8b153 !important;
}

.admin-input-design {
  width: 100%;
}

.profile-set {
  font-family: "poppins-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  color: #d4d9e1;
  text-transform: capitalize;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-header-profile-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 50%;
}

.arrow-profile-icon {
  height: 5px;
  width: 5px;
  object-fit: cover;
}

.admin-header-profile-ul a li {
  color: #122b45;
  font-size: 14px;
  line-height: 17px;
  font-family: "poppins-regular";
}

.admin-header-profile-ul a {
  text-decoration: none !important;
}

.logout-profile {
  color: #f5125f !important;
}

.admin-profile-box .MuiPaper-rounded {
  top: 50px !important;
  right: 32px !important;
  box-shadow: 0px 3px 6px #0000000d !important;
  border-radius: 10px !important;
  left: 0px !important;
}

/* / start admin header css / */
.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* / end admin header css / */

/* 10-5 css end */
.filter-header {
  background: #edeff1;
  padding: 15px 20px;
}

.filter-main .MuiPaper-elevation {
  min-width: 350px;
}

.filter-header-inner .filter-title {
  color: #003d90;
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 20px;
}

.filter-footer {
  height: 50px;
  border: 1px solid #edeff1;
  padding: 10px 20px;
}

.filter-inner-main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
}

.filter-header-inner {
  justify-content: space-between;
}

.outline-button button,
.outline-button button:hover {
  background: transparent;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.profile-content {
  height: 100%;
  min-height: calc(100vh - 120px);
  /* margin-top: 50px; */
}

.card-main {
  background: #f0f3f8;
  padding: 30px;
  box-shadow: 0px 3px 30px #0000000d;
  border-radius: 10px;
}



.profile-card-main {
  width: 710px;
}

/* .profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin: auto auto 15px auto !important;
} */

.change-input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "poppins-medium";
  padding-bottom: 6px !important;
  display: flex;
}

.set-textarea-box {
  height: 47px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-textarea-box:focus {
  outline: none;
}

.change-profile {
  display: flex;
  justify-content: center;
  position: relative;
}

input.change-profile-input {
  position: absolute;
  top: 14px;
  background: red;
  height: 32px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  width: 116px;
  margin: auto auto;
}

.change-password-set {
  margin-bottom: 20px;
}

.change-password-main {
  min-width: 370px;
}

.change-space {
  margin-bottom: 15px;
}

.supply-table tr th:nth-child(1),
.supply-table tr td:nth-child(1) {
  width: 6%;
}

.supply-table tr th:nth-child(2),
.supply-table tr td:nth-child(2) {
  width: 10%;
}

.supply-table tr th:nth-child(3),
.supply-table tr td:nth-child(3) {
  width: 10%;
}

.supply-table tr th:nth-child(4),
.supply-table tr td:nth-child(4) {
  width: 5%;
}

.supply-table tr th:nth-child(5),
.supply-table tr td:nth-child(5) {
  width: 11%;
}

.supply-table tr th:nth-child(6),
.supply-table tr td:nth-child(6) {
  width: 6%;
}

.supply-table tr th:nth-child(7),
.supply-table tr td:nth-child(7) {
  width: 8%;
}

.supply-table tr th:nth-child(8),
.supply-table tr td:nth-child(8) {
  width: 5%;
}

.supply-table tr th:nth-child(9),
.supply-table tr td:nth-child(9) {
  width: 5%;
}

.supply-table tr th:nth-child(10),
.supply-table tr td:nth-child(10) {
  width: 4%;
}

.supply-table tr th:nth-child(11),
.supply-table tr td:nth-child(11) {
  width: 7%;
}

.supply-table tr th:nth-child(12),
.supply-table tr td:nth-child(12) {
  width: 10%;
}

.supply-table tr th:nth-child(13),
.supply-table tr td:nth-child(13) {
  width: 18%;
}

.supply-table tr th:nth-child(14),
.supply-table tr td:nth-child(14) {
  width: 10%;
}

.tanker-table tr th:nth-child(1),
.tanker-table tr td:nth-child(1) {
  width: 12%;
}

.tanker-table tr th:nth-child(2),
.tanker-table tr td:nth-child(2) {
  width: 20%;
}

.tanker-table tr th:nth-child(3),
.tanker-table tr td:nth-child(3) {
  width: 12%;
}

.tanker-table tr th:nth-child(4),
.tanker-table tr td:nth-child(4) {
  width: 14%;
}

.tanker-table tr th:nth-child(5),
.tanker-table tr td:nth-child(5) {
  width: 18%;
}

.tanker-table tr th:nth-child(6),
.tanker-table tr td:nth-child(6) {
  width: 17%;
}

.tanker-table tr th:nth-child(7),
.tanker-table tr td:nth-child(7) {
  width: 20%;
}

.change-password-btn button {
  margin-left: 0 !important;
  margin-top: 5px;
}

.res-menu {
  display: none;
}

.res-menu-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.res-menu-icon svg {
  display: none;
}

.res-close-btn {
  display: none !important;
}

.header-flex-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.set-filter-main {
  padding-top: 60px;
}

.background-hight {
  height: 100%;
}

.header-ul-main-open {
  left: -250px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  width: 160px;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.no-text-decoration {
  text-decoration: none;
}

.margin-left-5 {
  margin-left: 5px !important;
}

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal .add-user-modal-inner-main {
  padding: 24px 24px 30px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal .modal-inner-main {
  max-height: calc(100% - 100px);
  overflow: auto;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px;
  background-color: #fff;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 5.5px 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* end add user modal css */
/* start delete user modal css */

.user-circle-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  /* margin: auto;
    display: flex; */
}

.modal-circle-main {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 10px 10px 10px 10px;
}

.modal-contained-wrap {
  padding: 10px 24px 30px 24px;
}

.modal-delete .delete-modal-inner-main {
  max-width: 460px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
  max-height: 85vh;
  overflow: auto;
}


.followers-modal-circle-main {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}

.no-flag-input .country-flag {
  display: none;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 42px;
  font-family: "poppins-medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #5c5c5c;
  margin-bottom: 26px;
  text-align: center;
  max-width: 360px;
  font-family: "poppins-medium";
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  gap: 16px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 20px;
  border: 1px solid #005c8a;
  max-width: 100px;
  width: 100%;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  background: #fff;
  border: 1px solid #005c8a;
  color: #005c8a;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background: #005c8a;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

.incre-btn-main .inc-dic-btn,
.incre-btn-main .inc-dic-btn:hover {
  background-color: #003d90;
  height: 34px;
  min-width: 34px;
  color: #fff;
  line-height: 23px;
  font-size: 17px;
  margin-top: 15px;
  border-radius: 5px;
  padding-bottom: 8px;
}

.incre-btn-main .inc-dic-btn:first-child {
  margin-right: 15px;
}

.incre-btn-main .Mui-disabled {
  color: #c5c1c1 !important;
}

.Mui-error {
  margin-left: 0px !important;
}

.action-menu-icon {
  font-size: 20px !important;
}

.action-text {
  font-family: "poppins-regular" !important;
  color: #122b45 !important;
  font-size: 13px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 23-5 */
.filter-datepicker .MuiOutlinedInput-input {
  border: 0 !important;
}

.filter-date-overflow>.MuiStack-root {
  overflow: hidden;
}

.pdf-design.MuiBox-root.css-0 {
  font-family: "poppins-medium";
  font-size: 14px;
  background: #003d90;
  color: #d8d8dd;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
}

.pdf-upload-design {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.filter-close-btn img {
  height: 23px;
  width: 23px;
}

.modal-close-button {
  height: 23px;
  width: 23px;
}

/* 9-6 */
/* / select compny start / */
.select-compny-cad-main {
  max-width: 1118px !important;
  width: 100%;
}

.select-card-main {
  background: #fff;
  padding: 50px;
  box-shadow: 0px 3px 30px #0000000d;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}

.title-main .select-page-title {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
  font-family: "poppins-semibold";
  text-align: center;
}

.compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 20.5px;
  background: white;
  cursor: pointer;
}

.compny-img-inner {
  width: 196px;
  height: 158px;
  border-radius: 10px;
}

.set-select-img {
  text-align: center;
}

.select-compny-text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: "poppins-semibold";
  color: #122b45;
}

.select-text-set {
  margin-top: 20px;
}

.select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.select-img-main {
  margin: 9px;
  width: 241px;
}

.mb-41 {
  margin-bottom: 41px;
}

.select-check-box {
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

.select-check-box svg {
  width: 30px;
  height: 30px;
}

.select-check-box .MuiCheckbox-colorPrimary {
  padding-top: 16px 0px 0px !important;
}

.select-modal-box {
  max-width: 551px !important;
  width: 100% !important;
  border: 1px solid #707070 !important;
  padding: 0px !important;
  border-radius: 20px !important;
  outline: none;
  cursor: pointer;
}

.select-modal-header {
  background: #003d90;
  padding: 15px 20px;

  border-radius: 19px 19px 0px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select-modal-header-text {
  font-size: 18px;
  color: white;
  line-height: 20px;
  font-family: "poppins-semibold";
}

.select-modal-img-box {
  padding: 40px 50px;
}

.select-text-set-modal {
  margin-top: 9px;
}

.modal-compny-img-inner {
  width: 94px;
  height: 101px;
  border-radius: 10px;
}

.modal-compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 13px 14px;
  background: white;
}

.modal-select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.select-img-main-modal {
  margin: 14px;
  width: 130px;
}

.modal-set-select-img {
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.modal-select-compny-text {
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  font-family: "poppins-semibold";
  color: #122b45;
}

.set-modal-close-icon {
  height: 30px;
  width: 30px;
}

.modal-close-img {
  height: 16px;
  width: 16px;
}

.modal-close-icon-small {
  height: 20px;
  width: 20px;
}

.modal-icon-btn {
  margin-top: 11px !important;
}

.res-btn-close {
  min-width: auto !important;
  padding: 0px !important;
}

.modal-compny-img .active {
  border: 1px solid #1f65db;
}

.select-img-main-modal.active .modal-compny-img {
  border: 2px solid #1f65db;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-grey {
  display: none;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-blue {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-grey {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-blue {
  display: none;
}

.select-img-main.active .compny-img {
  border: 2px solid #1f65db;
}

.select-img-main.active .select-check-box .modal-close-icon-small-blue {
  display: block;
  margin: auto;
}

.select-img-main.active .select-check-box .modal-close-icon-small-grey {
  display: none;
}

.select-img-main .select-check-box .modal-close-icon-small-grey {
  display: block;
  margin: auto;
}

.select-img-main .select-check-box .modal-close-icon-small-blue {
  display: none;
}

/* / select compny end / */
.barge-dashboard-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe0e1;
  border-radius: 10px;
}

.barge-common-box {
  background-color: #f0f3f8;
  padding: 20px;
  /* border: 1px solid #DFE0E1; */
  border-radius: 10px;
}

.table-design-main {
  background-color: #f0f3f8;
}

/* .page-content-box {
    margin-top: 30px;
    margin-bottom: 30px;
  } */
.dashboard-containt-main {
  padding: 60px 30px 0px;
}

.filter-date-overflow .MuiStack-root {
  width: 100%;
}

.barge-dashboard-page {
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-line-table tr th,
.one-line-table tr td {
  white-space: nowrap;
}

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium {
  padding: 3.5px;
}

.input-design-div .MuiInputBase-fullWidth {
  /* border-radius: 10px; */
  border-radius: 4px;
  height: 35px;
  line-height: 25px;
}

.input-design-div .set-date-picker .MuiInputBase-input {
  background: transparent;
}

/* .table-design-main tr th:last-child, .table-design-main tr td:last-child {
    width: 50px;
  } */
.white-login-box.MuiBox-root.css-0 {
  background: #fff;
  padding: 50px;
  border-radius: 15px;
}

.bluebox-text p {
  color: #122b45;
}

.bluebox-text span {
  color: #122b45;
}

.forgot_password {
  text-align: end;
}

.forgot_password a {
  display: inline-block;
}

.login-input-design-div .MuiFilledInput-underline,
.login-input-design-div .MuiFilledInput-underline:hover,
.login-input-design-div .MuiFilledInput-underline.Mui-focused {
  height: 36px;
  border: 1px solid #d4d9e1;
}

.logo-set2 {
  margin-bottom: 10px;
}

/* / 14/6/23 start css / */

.profile-image-center {
  text-align: center;
}

.input-design-div-set-back .MuiInputBase-input {
  background-color: transparent !important;
}

.change-password-icon .admin-input-design .MuiInputAdornment-filled button svg {
  width: 18px;
  height: 18px;
}

.change-password-icon .MuiInputBase-input {
  font-size: 14px !important;
}

.change-password-text {
  margin-bottom: 20px;
}

.input-design-div .password-set {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.input-design-div .password-set-box {
  margin-top: 0px !important;
}

.input-design-div .label-set {
  margin-top: 0px !important;
}

.input-label-new {
  background: #f0f3f8 !important;
}

/* / 14/6/23 start css / */

body .MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 8px;
  border: 1px solid #e1dddd;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

/* table start */

.table-design-main tr th {
  background: #a6ccff;
  padding: 20px !important;
  white-space: nowrap;
}

.dashboard-containt-main {
  padding: 60px 30px 0px;
  background: #f0f3f8 !important;
}

.table-design-main {
  background: #f0f3f8;
  border-collapse: collapse !important;
  border-spacing: 0px 3px !important;
  border-radius: 0px !important;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  padding-left: 20px !important;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  padding-right: 20px !important;
}

.barge-common-box-table {
  background-color: #f0f3f8;
  padding: 0px !important;

  border-radius: 10px;
}

.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.table-design-main tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-design-main tr td {
  background: #fff;
  /* display: table-cell; */
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  width: 90px;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  border-radius: 10px 0px 0px 10px;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
}



.profile-inner .dashboard-item {
  background: transparent;
}

.set-textarea-box {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-profile-set {
  font-size: 14px;
  font-family: "poppins-semibold";
  line-height: 20px;
  color: #122b45;
  padding: 0px 8px;
  text-transform: capitalize;
  display: flex;
}

.set-admin-header-profile-icon {
  width: 33px !important;
  height: 33px !important;
}

.set-profile-inner {
  font-size: 8px;
  line-height: 10px;
  color: #959595;
  padding: 0px 8px;
  font-family: "poppins-regular";
}

.new-menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;
  width: 200px;
}

.pink-btn-menu {
  background: #ffb8d0 !important;
  text-align: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin: 0px 16px !important;
}

.drop-header-btn-set {
  padding: 0px 0px 6px !important;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 16px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;

  width: 200px;
}

.css-2hzjrb-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}

.table-design-main tr td:first-child {
  padding-left: 20px !important;
  white-space: nowrap;
}

.input-design-div fieldset {
  border-color: #ccc;
  border-radius: 0px;
}

.invoice-table tr td {
  white-space: nowrap;
}

.paiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  margin-right: 10px;
}

.unpaiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 10px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.Mui-selected {
  background: #a6ccff;
}

.input-design-div .MuiOutlinedInput-root:hover fieldset {
  border-color: #ccc;
  border-width: 1px;
}

.MuiOutlinedInput-root .MuiSelect-select .multiselect-item {
  display: inline-flex;
  background: #c9e0ff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 2;
  font-family: "poppins-semibold";
  color: #003d90;
}

.invoice_number {
  display: flex;
  justify-content: space-between;
}

.invoice_number_align {
  margin-left: 10px !important;
  font-size: 16px !important;
}

.party_btn {
  display: flex !important;
  justify-content: start;
  margin-right: "-20px";
}

.admin-page-title {
  color: #003d90;
  font-size: 19px !important;
  line-height: 20px !important;
  font-family: "poppins-bold" !important;
}

/* / Page-not-found / */
.pagenotfound-text-heading {
  margin-top: 10px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center;
}

.pagenotfound-text {
  text-align: center;
}

.pagenotfound-img {
  margin-top: 10px !important;
  width: 80% !important;
  height: 70% !important;
}

.table-data-not-found {
  text-align: center;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: gray;
}

.loading-spinner {
  align-items: center !important;
  display: flex;
  justify-content: center;
}

.Toastify__toast-body>div:last-child {
  word-break: break-word;
  flex: 1 1;
}

.Toastify__toast-body {
  margin: auto 0;
  /* flex: 0 0 auto; */
  padding: 6px;
  display: flex;
  align-items: center;
}

/* .table_scroll ::-webkit-scrollbar {
  display: none;
} */

.gridtable tbody {
  display: block;
  max-height: 310px;
  overflow-y: scroll;
}

.gridtable thead,
.gridtable tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.gridtable thead {
  position: relative;
}

.gridtable thead th:last-child:after {
  content: " ";
  position: absolute;
  height: 38px;
  right: -1.3em;
  top: 0;
  border-bottom: 2px solid #ddd;
}

.episap-table-main .loading-row .loading-cell {
  height: 358px !important;
  /* border-radius: 10px 10px 10px 10px ; */
}

.user-details .loading-row .loading-cell {
  height: 250px !important;
  /* border-radius: 10px 10px 10px 10px ; */
}

.episap-table-main tr th {
  background: #a6ccff;
  font-family: "poppins-semibold";
}

.episap-table-main tr th,
.episap-table-main tr td {
  color: #122B45;
  font-size: 12px;
  padding: 9px;
}

.episap-table-main tr td {
  font-family: 'poppins-regular';
  /* padding: 0px 13px 2px 13px; */
  padding: 1px 9px 1px 9px;
  max-height: 34px;
  justify-content: space-evenly;
}

.contact-table tr td {
  padding: 10px 13px 10px 13px;
}

.episap-table-container {
  /* max-height: 397px; */
}

.episap-comment-container {
  /* max-height: 320px; */
  overflow-y: scroll;
}

.episap-user-details-table {
  /* max-height: 300px; */
}

.resend-opt-button:hover {
  background: none !important;

}

.resend-opt-button .MuiButtonBase-root:hover {
  background: none !important;
}

.resend-opt-button:focus {
  background: none !important;
}

.table-body .tablecell-click {
  cursor: pointer;
}

.table-body .tablecell-click:hover {
  color: #003d90;
  font-size: 15px;
}

.input-design-div .datepicker .MuiInputBase-input {
  padding: 8.5px 10px !important;
}

.datepicker fieldset {
  border: 1px solid #ccc !important;
}

.css-1q04gal-MuiDateCalendar-root {
  max-height: 322px !important;
}

.error-text {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px !important;
  margin-right: 14px;
}

.remark-text-area {
  margin-bottom: 16px !important;
}

.remark-text-area textarea::placeholder {
  margin-bottom: 16px !important;
}

.status-select .MuiInputBase-input {
  /* padding: 10px 16px !important; */
  padding: 7px 10px !important;
  color: #122b45;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
}

.status-select fieldset:focus {
  border: 1px solid #ccc;
}

/* .admin-input-design  */
.status-select .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ccc !important;
}

.admin-input-design .MuiSelect-select:focus {
  background-color: #fff;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline */

.image-modal-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
}

.image-modal-box .document-box {
  /* height: 300px; */
}

img.documents-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding: 1px;
}

.document-back {
  margin-bottom: 10px;
}

.documents-main-box {
  height: 500px;
}

.sidebar-icon-box img {
  width: 23px;
  height: 23px;
  object-fit: cover;
}



.error-text-ck {
  color: #d32f2f;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-family: "poppins-medium";
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px !important;
  display: flex;
}

.pagenotfound-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg-input input {
  width: 100%;
}

.css-h5fkc8 {
  margin-top: 16px !important;
}

.change-pwd-cont .css-zs07ro-MuiInputBase-root-MuiFilledInput-root {
  border: 1px solid #d4d9e1;
  width: 100% !important;
  height: 36px;
  line-height: 31px;
  border-radius: 0px !important;
  margin-right: 0px !important;
  background-color: #fff !important;
}

.icon-box-all {
  margin-top: 40px;
  margin-bottom: 60px;
}

.icon-img-box a.upload-document-img {
  border-radius: 8px;
}

.icon-img-box a.upload-document-img img {
  height: 66px;
  width: 66px;
  padding: 4px;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  border: 2px solid #005c8a;
}


.add-word-break {
  word-break: break-all;
}

.loader-white span{
  color: #fff;
}

.wallet-box-content-dark {
  background-color: #131314 !important;
} 

.wallet-box-content-dark .wallet-box-cont .logo-title,
.wallet-box-content-dark .wallet-box-cont .wallet-title, 
.wallet-box-content-dark .wallet-success-msg, 
.wallet-box-content-dark .wallet-box-cont .add-word-break, 
.wallet-box-content-dark .wallet-box-cont .payment-timer {
  color:#fff;
}
.admin-main .btn.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    min-width: unset;
    border-radius: var(--radius6);
    font-size: 14px;
    line-height: 18px;
    font-family: "poppins-semibold";
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid transparent;
    padding: 10px 20px;
}

.admin-main .btn.btn-secondary {
    background-color: transparent;
    color: var(--primary);
    min-width: unset;
    font-size: 14px;
    line-height: 18px;
    font-family: "poppins-semibold";
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid var(--primary);
    padding: 10px 20px;
}

.admin-main .btn {
    padding: 0;
    min-width: unset;
}

.admin-main .form-group .form-lable {
    color: #989898;
    font-size: 14px;
    line-height: 18px;
    font-family: "poppins-semibold";
    margin-bottom: 6px;
    margin-top: 0;
}

.admin-main .form-group .form-input input {
    /* background-color: #fff; */
    border-radius: 0;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 15px;
    color: #fff;
}


.admin-main .form-group .form-input.login input {
    /* background-color: #fff; */
    border-radius: 0;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 15px;
    color: #fff;
}

.admin-main .form-group .form-input.login .css-2y464i-MuiInputBase-root-MuiFilledInput-root:before,
.admin-main .form-group .form-input.login .css-2y464i-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none !important;
}

.admin-main .form-group .form-input.login .css-2hzjrb-MuiInputBase-input-MuiFilledInput-input:-webkit-autofill {
    border: 1px solid #fff !important;
}

.admin-main .form-group .form-input.login input:-webkit-autofill,
.admin-main .form-group .form-input.login input:-webkit-autofill:hover,
.admin-main .form-group .form-input.login input:-webkit-autofill:focus,
.admin-main .form-group .form-input.login input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #ffffff;
}

.admin-main .form-group .form-input.login svg.css-i4bv87-MuiSvgIcon-root {
    fill: white;
}

.admin-main .form-group .form-input .css-1tzgnuz-MuiInputBase-root-MuiFilledInput-root,
.admin-main .form-group .form-input .css-2hzjrb-MuiInputBase-input-MuiFilledInput-input {
    padding-right: 0;
    /* background-color: #fff; */
    border-radius: 0;
    border: 1px solid #ffff;
}

.admin-main .form-group .form-input .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    margin-right: 0;
}

.admin-main .form-group .form-input fieldset,
.admin-main .form-group .form-input .MuiOutlinedInput-root:hover fieldset {
    border: 1px solid var(--secondary);
}

.admin-main .form-group .form-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border: 1px solid var(--primary);
}

.admin-main .form-group .form-checkbox {
    margin: 0;
    gap: 5px;
    align-items: flex-start;
}

.admin-main .form-group .form-checkbox .MuiCheckbox-root {
    padding: 0;
}

.admin-main .form-group .form-checkbox .MuiSvgIcon-root {
    color: var(--primary);
    font-size: 20px;
}

.admin-main .form-group .form-checkbox .MuiTypography-root {
    font-size: 14px;
    line-height: 18px;
    color: #989898;
}

.admin-main .dahboard-common-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.admin-main .dahboard-common-header .header-title {
    font-size: 24px;
    line-height: 30px;
    font-family: "poppins-semibold";
}

.admin-main .common-table-main {
    box-shadow: var(--shadow-sm);
    border-radius: var(--radius6);
}

.admin-main .common-table-main .action-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-main .common-table-main .action-box button {
    padding: 8px;
    border-radius: 50%;
}

.admin-main .common-table-main .action-box svg {
    width: 20px;
    height: 20px;
}
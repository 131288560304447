.view-icon {
  color: #003d90;
  /* margin-top: 6px; */
  cursor: pointer;
}


/* .nft--owner-detail{
padding: 30px 10px;
} */



.nft-heading-box .nft-heading {
  color: #003d90;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold";
}

.nft-heading-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft-heading-box .nft-creator-heading {
  color: #003d90;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold";
  margin-left: 20px;
  margin-top: -10px;
}

.user-image-wrape .nft-image {
  margin: 15px 0px;
}

.profile-detail .nft-category {
  color: #204e7e;
  font-size: 13px;
  line-height: 15px;
  font-family: 'inter-regular';
  margin-bottom: 23px;
}

.main-image-nft {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-image label {
  color: #122B45;
  font-size: 14px;
  line-height: 15px;
  font-family: 'inter-semibold';
  margin-bottom: 5px;
  display: block;
}


.nft_tabel thead tr th {
  width: 15%;

}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(1),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(1) {
  width: 2%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(2),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(2) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(3),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(3) {
  width: 6%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(4),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(4) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(5),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(5) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(6),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(6) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(7),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(7) {
  width: 6%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(8),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(8) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(9),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(9) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(10),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(10) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(11),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(11) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(12),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(12) {
  width: 4%;
}

.cus-table-scroll .master-warden-nft-table .table-th:nth-child(13),
.cus-table-scroll .master-warden-nft-table .table-td:nth-child(13) {
  width: 3%;
}

.cus-table-scroll .master-warden-nft-table {
  min-width: 1300px;
}

.warning-icon {
  width: 25px;
  height: 25px;
}

.nft-detail-img {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nft-detail-img .user-detail-img {
  height: 126px;
  width: 126px;
}

.admin-nft-detail {
  padding: 20px;
  border-radius: 6px;
  height: 100%;
  columns: 3;
}
.admin-nft-detail.height-auto{
  height: auto;
}

.admin-nft-detail.colmn-1{
  columns: 1;  
  padding-top: 0;
}

.admin-nft-detail.colmn-1 .user-detail-title {
  min-width: 100px;
  font-size: 14px;
  margin-bottom: 8px;
}

.admin-nft-detail.colmn-1 .user-detail-descr{
  font-size: 13px;
  position: relative;
  font-family: "poppins-medium";
  word-break: break-word;
}

.admin-nft-detail.colmn-1 .read-btn{
  font-size: 12px;
  padding: 0;
  /* margin-left: 10px; */
  color: var(--primary);
  font-weight: bold;
  /* position: absolute;
  right: 0px;
  bottom: -4px; */
  text-transform: capitalize;
}

.admin-nft-cont {
  margin-bottom: 8px;
  display: flex;
  align-items: start;

}

.admin-nft-cont .user-detail-title{
  /* min-width: 86px; */
  min-width: max-content;
}

.admin-nft-cont .user-detail-title.title-max-width {
  min-width: max-content;
}

.admin-nft-cont .user-detail-subtitle {
  margin-left: 5px;
  overflow-wrap: anywhere;
}

.admin-nft-cont span {
  font-size: 14px;
}

.admin-nft-cont .user-detail-subtitle span {
  overflow-wrap: anywhere;
  font-size: 14px;
}

.user-detail-cont.bg-right {
  background: #fffdfa;
}

.title-header-flex.res-title-header-flex.justify-content-between {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.user-detail-img.nft-detail-box {
  width: 52px;
  height: 52px;
  margin: 0;
}

.user-detail-cont.pb-0.nft-top-bg:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.user-detail-cont.pb-0.nft-top-bg .page-title.page-sub-title {
  position: relative;
  z-index: 1;

}

.nft-user-detail {
  display: flex;
  align-items: start;
  gap: 8px;
}
.box-otp-text1 {
  font-family: 'poppins-regular';
  line-height: 15px;
  margin: 20px 207px -10px 0px;
}

.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}

.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 0px;
  border: 0px;
  margin: 10px 8px;
  background: transparent;
  font-size: 20px;
  border: 1px solid #c9c9c9;
  color: #fff;
}
.inputStyle.txt-black{
  color: #000;
}
.inputStyle:first-child{
  margin-left: 0px;
}

.back-link-box {
  text-align: start;
}

.back-link-box a {
  display: inline-block;
}

.box-login-text1{
    font-family: 'poppins-regular';
    line-height: 15px;
    margin: 20px 170px -10px 0px;
}

.bluebox-text1{
    color: #122B45;

}
.back-wraper{
    display: flex;
}
.back-wraper .back-icon{
    color: #fff;
    margin-right: 3px;
}
.back-wraper p{
    color: #fff !important;
}

.back-link-box{
    text-align: start;
}
.back-link-box a{
    display: inline-block;
}
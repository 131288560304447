/* For screens with width up to 600px */
@media (max-width: 768px) {
  .common-form-box .form-back-btn {
    width: 100%;
    text-align: end;
  }
}

@media (max-width: 600px) {
  .modal {
    width: 100%;
  }
}

@media (min-width:768px) {
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* For screens with width from 601px to 1200px */
@media (min-width: 601px) and (max-width: 1200px) {
  .modal {
    width: 55%;
    margin: auto !important;
  }
}

/* For screens with width above 1200px */
@media (min-width: 1201px) {
  .modal {
    width: 50%;
    /* width: 30%; */
    margin: auto !important;
  }
}


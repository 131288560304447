@media (max-width:1024px) {
    .user-detail-img {
        height: 100px;
        width: 100px;
    }

    .episap-common-box.pl-0.pr-0 {
        padding: 16px !important;
    }
}

@media (max-width:768px) {
    .episap-common-box.pl-0.pr-0 {
        padding: 16px !important;
    }

    .tab-back-btn .form-back-btn {
        position: relative;
        text-align: end;
        margin-top: 5px;
    }
}


@media (max-width:375px) {

    .admin-nft-cont span,
    .user-detail-title,
    .user-detail-subtitle {
        font-size: 12px;
    }
}
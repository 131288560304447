@media (max-width: 1199px) {
    .admin-main .dashboard-main .total-counter-box,
    .admin-main .dashboard-main .all-component {
        grid-template-columns: repeat(2, 1fr);
    }
    
}

@media (max-width: 549px) {
    .admin-main .dashboard-main .total-counter-box,
    .admin-main .dashboard-main .all-component {
        grid-template-columns: repeat(1, 1fr);
    }

    .admin-main .dashboard-main .total-counter-item .counter-number {
        font-size: 20px;
        line-height: 26px;
    }
    .filter-cont .filter-table-cont{
        min-width: 100px;
        margin-bottom: 8px;
    }
}
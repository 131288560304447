.admin-main .dashboard-common-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    font-family: "poppins-semibold";
}

.admin-main .btn {
    padding: 0;
    min-width: unset;
}


.admin-main .dashboard-common-header .header-title {
    font-size: 22px;
    line-height: 30px;
    font-family: "poppins-semibold";
}

.admin-main .dashboard-common-header .header-subtitle {
    font-size: 18px;
    line-height: 30px;
    font-family: "poppins-semibold";
}

.admin-main .search-text,
.admin-main .search-text:hover {
    border: 1px solid var(--border-color);
}

.btn.btn-primary {
    background-color: var(--primary);
    color: var(--white);
    min-width: 120px;
    padding: 6px 12px;
    border: 1px solid var(--primary);
}

.btn.btn-primary:hover {
    background-color: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
}


.btn.btn-secondary {
    background-color: var(--white);
    color: var(--primary);
    min-width: 120px;
    padding: 6px 12px;
    border: 1px solid var(--primary);
}

.btn.btn-secondary:hover {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);
}

/* Modal CSS */
.auth-modal-cont {
    background-image: url('../images/png/background-dashboard.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.cms-text {
    display: flex;
    justify-content: space-between;
    font-family: "poppins-medium";
}

.error-otp-text.error-text {
    color: #ff0000;
}

.resend-otp-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.acc-already-txt.resend-otm-timer.text-white,
.acc-already-txt.resend-link.text-white a {
    color: #fff;
    font-size: 14px;
}

.follow-list-section.follow-list-section-img img {
    width: 480px;
    height: 320px;
    object-fit: contain;
}

.admin-main .auth-btn-box button.login-disable {
    color: #fff !important;
}


.logo-box {
    width: 100%;
}

.logo-box .logo-title {
    font-weight: bold;
    font-size: 18px;
    display: block;
    text-align: center;
}

.logo-box img {
    width: 100%;
    height: 60px;
    object-fit: contain;
}

.wallet-box {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
    position: relative;

}


.wallet-box-cont {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    text-align: center;
    padding: 16px;
}

.wallet-box-cont .wallet-title {
    font-size: 18px;
    font-weight: bold;
}

.wallet-subtitle {
    font-size: 15px;
    font-weight: bold;
    color: #d43e2d;
}

.wallet-rs {
    font-size: 16px;
    font-weight: bold;
}

.wallet-title-box .logo-title {
    margin-bottom: 16px;
}

.wallet-btn-approve button {
    padding: 8px 20px;
    background-color: #2d98d4;
    font-size: 13px;
    color: #fff;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.lg-box-name {
    word-break: break-all !important;
    width: 150px;
}

.set-textarea-box-top.address-input {
    height: 100px;
}

.set-textarea-box-top.address-input textarea {
    height: 80px !important;
}

.wallet-loader {
    min-width: 100%;
    width: 100%;
    height: 100%;
    padding: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    cursor: pointer;
    transition: 0.3s linear;
    top: 0;
    margin: 0 auto;
}

.wallet-loader::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(5px);
}


.user-detail-subtitle a.open-link {
    text-decoration: none;
    color: #fff;
    background: #005c8a;
    padding: 4px;
    font-size: 10px;
    border-radius: 10px;
}

.verify-icon-container {
    margin: 0px 5px;
}

.verify-icon-container svg {
    font-size: 20px;
}

.check-btn-icon {
    padding: 0 6px 0px 0px !important;
}
.single-leaf-box{
    display: flex;
    justify-content: space-between;
    flex-direction: column !important;
}
.single-leaf-cont {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.single-leaf-cont label {
    padding-bottom: 0 !important;
    color: #005c8a !important;
}

.Idealeaft-view-inpt-main label {
    color: #005c8a !important;
}

.single-leaf-cont .input-design-div {
    margin-bottom: 0px;
}

.Idealeaft-view-inpt-main .user-detail-descr.input-design-div.with-border {
    font-size: 14px;
}

.Idealeaft-view-inpt-main .user-detail-descr.input-design-div.with-border .read-btn {
    font-size: 14px;
    padding: 0;
    color: #005c8a;
    text-transform: capitalize;
    margin-left: 4px;
}

.single-page-loader{
    display: flex;
    justify-content: center;
}

.single-page-loader td {
    border-bottom: 0;
}

.pagenotfound-img.servernotfound {
    margin-top: 10px !important;
    width: 60% !important;
    height: calc(100vh - 50%) !important;
}

/* Notification Page */
.notification-main-box {
    position: relative;
    border: 1px solid #005c8a;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
  }
  
  .notification-box {
    display: grid;
    grid-template-columns: 0.2fr 5fr 0.5fr;
    gap: 16px;
    align-items: center;
  }
  
  .notification-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  
  .notification-img img {
    height: 100%;
    width: 100%;
  
  }
  
  .notification-text {
    font-size: 16px;
    font-weight: bold;
  }
  
  .notification-sub-text,
  .notification-time {
    font-size: 14px;
  }
  
.top-right-notification {
    position: absolute;
    right: 10px;
    top: 10px;
  }

.email-social-icon {
    display: flex;
    gap: 5px;
}
/* End Notification Page */
.reg-input.reg-mobile-num.cont-mobile,.reg-input.reg-mobile-num.cont-mobile .error-text{display: block; width: 100% !important;}
label.input-label.contact-mobile{
    padding-bottom: 0px !important; 
}

.wallet-address {
    display: flex;
    gap: 6px;
}

.referell-box{
    display: flex;
    gap : 16px;
    justify-content: end;
    height: 100%;
    align-items: end;
}

.set-date.set-date-picker.picker-box-set .MuiInputBase-colorPrimary{
    border: 0 !important;
}
.table-title-word-break {
    word-break: break-word;
}
.wallet-btn-approve .disable-color {
    opacity: 0.6;
    cursor: not-allowed;
}
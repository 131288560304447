.form-label {
    color: #122B45;
    font-family: 'poppins-regular';
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
    min-width: 87px;
    background-color: #114A65;
    color: #FFFFFF;
    height: 36px;
}

.border-btn-main .border-btn {
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 6px 14px 5px;
    font-size: 12px;
    line-height: 18px;
    color: red;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
    min-width: 100px;
    color: #fff;
    background-color: #005c8a;
    border-radius: 0;
    font-family: "poppins-semibold";

}

.border-btn-main .border-btn {
    border: 1px solid #005c8a;
    border-radius: 4px;
    background: #005c8a;
    color: #fff;
    padding: 6px 14px 5px;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins-Regular';
    text-transform: capitalize;
}

.user-save-icon {
    margin-right: 8px;
    object-fit: contain;
}

.form-control {
    background-color: white;
}

.save-user-btn img {
    margin-right: 5px;
}

.cms {
    padding-top: 20px;
}

/* .add-user-btn {
    padding: 4.5px 15px !important;
} */

.register-modal-main h5 {
    color: #000000;
    font-size: 28px;
    font-family: 'poppins-semibold';
}

.register-modal-main p {
    color: #000000;
    font-size: 14px;
    font-family: 'poppins-regular';
    padding-top: 9px;
}

.reg-input {
    width: calc(100%/2);
    padding: 0 6px;
    padding-bottom: 6px;
}
.reg-input.warden-modal-box{
    width: 100%;
    padding: 0 6px;
    padding-bottom: 6px;
}

.reg-input .MuiInput-underline {
    width: 100%;
    margin: 6px 0 0px 0;
    height: 36px;
}

.reg-input .MuiInput-underline::before,
.reg-input .MuiInput-underline::after {
    display: none;
}

.reg-input .MuiInput-underline input {
    padding: 6px 14px;
    background: #FFF;
    border-radius: 0px;
    font-size: 14px;
    font-family: 'poppins-regular';

    border: 1px solid #d4d9e1;
}

.reg-input .MuiInputBase-formControl {
    background: #FFF;
    border-radius: 0px;
    font-family: 'poppins-regular';
    margin: 6px 0 6px 0;
    border: 1px solid #d4d9e1;
    font-size: 14px;
}

.reg-input .MuiInputBase-formControl input {
    padding: 6px 14px;
}

.reg-input .MuiInputBase-formControl fieldset {
    border: none;
}

.re-input-label {
    color: #9496A5;
    font-size: 14px;
    font-family: 'poppins-semibold';
}

.register-all-inputs {
    /* padding: 20px; */
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.reg-input .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.reg-input .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.terms-condi {
    display: flex;
    padding-left: 5px;

}

.terms-condi .MuiCheckbox-colorPrimary {
    padding: 0;
    display: flex;
    align-items: flex-start;
    margin-right: 5px;
}

.terms-condi p {
    padding: 0;
}

.reg-mobile-num .MuiInputAdornment-positionStart {
    position: absolute;
    padding-left: 10px;
}

.reg-mobile-num .MuiInput-underline input {
    padding-left: 50px;
}

.modal-footer-btn {
    text-align: center;
    margin: 0 auto;
    padding-top: 8px;
    padding: 0px 16px;
}

.modal-footer-btn .submit-btn {
    /* min-width: 345px;
    min-height: 45px;
    border-radius: 5px;
    background-color: #000000;
    color: #FFFFFF;
    font-size: 17px; */
    background-color: #005c8a;
    border-radius: 20px;
    font-family: 'poppins-semibold';
    margin-bottom: 16px;
}

.acc-already-txt a {
    font-family: 'poppins-regular';
    font-size: 14px;
    line-height: 18px;
    color: #727272;
    text-decoration: none;
}

.acc-already-txt {}

.reg-drop .select-cat {
    width: 100%;
    margin: 0;
}

.reg-drop .select-cat .MuiInputBase-colorPrimary {
    margin: 9px 0 10px 0;
}

.reg-drop .select-cat .MuiSelect-select {
    padding: 6px 8px;
    background: #FFF;
    border-radius: 5px;
    font-family: "poppins-medium";
    font-size: 14px;
}

.reg-drop .select-cat fieldset {
    border: none;
}

.select-cat p {
    padding: 0;
}

.reg-otp .MuiOtpInput-TextField .MuiOutlinedInput-input {
    padding: 0;
}

.reg-otp .MuiInputBase-colorPrimary {
    width: 60px;
    height: 55px;
    background: #F6F6F6;
}

.reg-otp .MuiInputBase-colorPrimary input {
    font-family: 'poppins-regular';
    color: #000000;
}

.reg-otp .MuiInputBase-colorPrimary fieldset {
    border: none;
}

.reg-otp .MuiOtpInput-Box {
    justify-content: space-between;
    margin: 9px 0 10px 0;
}

.ver-border {
    border-left: 1px solid rgb(177 179 189);
}

.resend-otm-timer p {
    padding: 0;
    margin-left: 8px;
}

.resend-otm-timer {
    display: flex;
    align-items: center;
}

.resend-link a {
    text-decoration: underline;
    font-family: "poppins-semibold";
}

.resend-link {
    text-align: end;
}

.icon-btns {
    min-width: 36px !important;
    color: #005c8a !important;
}


/* New CSS for CMS Menu */
/* .episap-cms-box .input-box .form-control{
    height: 36px;
    line-height: 1.6;
    border-radius: 0px !important;
    margin-right: 10px !important;
    background-color: #fff !important;    
} */

.episap-cms-box .input-box .form-control {
    height: 40px !important;
    line-height: 1.8 !important;
    background-color: #fff !important;
}

.episap-cms-box .input-box .form-control .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 0px !important;
}

.form-group.epibox-editor .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    height: 260px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    height: 260px !important;
    padding: 0px 28px;
}

.editor-btn {
    width: 100%;
    margin: 0 auto;
    margin-top: 16px !important;
}

.editor-btn .css-1tpdlvz {
    margin-top: 16px;
}

button.editor-submit-btn {
    background-color: #005c8a;
    border-radius: 0px;
    font-family: "poppins-semibold";
    color: #fff;
    min-width: 120px;
    justify-content: space-evenly;
}

button.editor-submit-btn:hover {
    background-color: #005c8a;
    color: #fff;
}

.title-main.cms-heading {
    margin: 0 auto;
}

.reg-input.reg-mobile-num input{
    border: 0;
}
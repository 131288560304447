* {
    outline: none;
}

.user-detail-section {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #a5c7f5;
    display: flex;
    overflow: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

.left-wrape {
    width: 280px;
    border-right: 1px solid #a5c7f5;
}

.profile-wrape {
    width: 100%;
    height: 150px;
    position: relative;
    background: #003d90;
}

.profile-wrape .image-wrape {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    bottom: -40px;
    left: 50%;
    overflow: hidden;
    border: 6px solid #ffffff;
    margin-left: -40px;
}

.profile-wrape .image-wrape img {
    transform: scale(1.15);
}

.image-wrape img {
    transform: scale(1.15);
    width: 80px;
    height: 80px;

}

.detail {
    padding: 60px 15px;
}

.detail label {
    color: #122B45;
    font-size: 14px;
    line-height: 15px;
    font-family: 'poppins-semibold';
    margin-bottom: 5px;
    display: block;
}

.detail p {
    color: #204e7e;
    font-size: 13px;
    line-height: 15px;
    font-family: 'poppins-regular';
    margin-bottom: 23px;
}

.right-wrape {
    display: flex;
    width: calc(100% - 280px);
    flex-wrap: wrap;
}

.stat-section {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #a5c7f5;
    max-height: 105px;
}

.stat-section .mini-card {
    width: calc(45% / 2);
    padding: 10px 15px 10px 15px;
    border-right: 1px solid #a5c7f5;
    display: flex;
    margin: 0;
    height: auto;
    flex-basis: inherit;
    max-width: inherit;
    justify-content: flex-start;
    cursor: pointer;
}

.stat-section .icon-wrape {
    width: 48px;
    height: 48px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
    margin-right: 10px;
}

.legend {
    color: #585d65;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 2px;
    font-family: "poppins-regular";
}

.stats {
    color: #003d90;
    font-size: 20px;
    line-height: 1.5;
    font-family: "poppins-bold";
    word-break: break-word;
}

.stat-section .big-card {
    padding: 10px 0;
    overflow: hidden;
}

.stat-section .big-card .detail-wape {
    padding: 0 15px;
}

.community-wrape {
    overflow: auto;
    white-space: nowrap;
}

.community-wrape .community {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    overflow: hidden;
    border: 1px solid #b8b8b8;
    margin: 5px 0 5px 15px;
    transition: all 0.15s linear 0.15s;
    display: inline-block;
    cursor: pointer;
}

.community-wrape .community:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.15s linear 0.15s;
    border-color: #ffffff;
}


.table-wrape {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 20px;
}

.table-wrape .barge-common-box {
    padding: 15px;
}

.table-wrape .title-header-flex {
    align-items: center;
}

.table-wrape .search {
    margin-right: 0 !important;
}


.user-detail-popup.barge-common-box {
    background-color: #f0f3f8;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
}

.user-detail-popup .title-main .page-title {
    color: #003d90;
    font-size: 19px;
    line-height: 20px;
    font-family: 'poppins-bold';
    white-space: nowrap;
}

.user-detail-popup.barge-common-box .modal-body {
    max-height: 80vh;
    overflow: auto;
}

.follow-list-section {
    max-height: 80vh;
    overflow: auto;
}

.community-popup .modal-body .follow-list-section {
    max-height: 45vh;
}



.user-detail-popup .list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #d9d9d9;
    padding-top: 15px;
    margin-bottom: 10px;
    align-items: center;
}

.user-profile-view {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.image-main-wrapper {
    display: flex;
    align-items: center;
}


.user-detail-popup .list .detail-text {
    color: #122B45;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 3px;
    font-family: 'poppins-semibold';
}

.user-detail-popup .list .date-like-dislike-model {
    color: #122B45;
    font-size: 14px;
    line-height: 15px;
    font-family: 'poppins-semibold';
}

.image-wrape {
    display: flex;
}

.image-wrape .img-wraper-img {
    height: 100%;
    /* margin-right: 15px; */
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.user-detail-popup .list .details-user-name {
    color: #7a6868;
    font-size: 12px;
    line-height: 15px;
    font-family: 'poppins-regular';
}

.user-detail-popup .community-detail {
    display: flex;
    position: relative;
    background: #003d90;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.user-detail-popup .community-detail .image-wrape {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #f0f3f8;
    margin-right: 0;
    transition: all 0.15s linear 0.15s;
    position: relative;
    /* top: -20px; */
}

.user-detail-popup .community-detail .image-wrape:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.15s linear 0.15s;
    border-color: #ffffff;
}

.user-detail-popup .group-detail-wrape {
    width: 100%;
    text-align: center;
    padding: 0 15px 20px;
    color: #fff;
}

.user-detail-popup .group-detail-wrape h6 {
    font-size: 20px;
}

.user-detail-popup .group-detail-wrape span {
    font-size: 14px;
    opacity: 0.7;
    font-family: "poppins-regular";
}

.user-detail-table-container {
    max-height: 300px;
}


/* .list .date-main-wrapper{
    right: 144px;
    position: absolute;
} */



.user-detail-box {
    min-width: 120px;
    margin-right: 10px;
}

.user-detail-cont {
    padding: 20px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    position: relative;
    z-index: 1;
    /* height: 100%; */
    display: flex;
    align-items: center;
}

.user-detail-cont:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    margin-bottom: 8px;
    content: "";
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.user-detail-cont.bg-right::after {
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 10px;
    height: 100%;
    content: "";
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.user-detail-cont.bg-right::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    width: 10px;
    height: 100%;
    content: "";
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.user-cont-box {
    background-color: #585d65;
}

.user-detail-img {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    border: 1px solid #e5e5e5;
}

/* .user-detail-img.group-member-img{
    width: 68px;
    height: 68px;
} */

.user-detail-cont.bg-right .user-detail-img {
    margin: 0;
}

.user-detail-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
}

/* .user-detail-img.nft-img-fill img{
    object-fit: fill;
} */

.list.modal-total-views .image-main-wrapper .user-detail-title,
.user-detail-title {
    font-size: 14px;
    font-family: "poppins-semibold";
    color: var(--black);
}


.list.modal-total-views .image-main-wrapper .user-detail-subtitle,
.user-detail-subtitle {
    font-size: 14px;
    color: var(--primary);
    font-family: "poppins-medium";
}

.user-detail,
.user-group-box-detail {
    margin-bottom: 12px;
    width: 100%;
    position: relative;
    padding-left: 12px;
}

.user-detail-card {
    margin-bottom: 8px;
    width: auto;
}

.user-detail:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    content: "";
}

.user-detail:nth-child(1):before {
    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(237, 3, 32, 0.87) 20.8%, rgba(242, 121, 1, 0.84) 74.4%);
}


.user-detail:nth-child(2):before {
    background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}

.user-detail:nth-child(3):before {
    background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}

.user-detail:nth-child(4):before {
    background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}

.user-detail:nth-child(5):before {
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}



.follow-card {
    display: grid;
    /* grid-template-columns: 1.5fr 1.5fr 3fr;     */
    grid-template-columns: 1fr;
    gap: 16px;
    width: 100%;
}

.follow-card .follow-card-cont {
    background-color: #b6dff9;
    box-shadow: var(--shadow-sm);
    height: 100%;
    padding: 10px;
    z-index: 1;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
}

.follow-card-cont .follow-icon-wrap {
    width: 48px;
    height: 48px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
    margin-right: 10px;
    background-color: #fff;
}

.episap-common-box.pl-0.pr-0 {
    padding: 15px 0px;
}


.tab-back-btn .form-back-btn {
    position: absolute;
    right: 0;
    top: 0;
}

.user-group-box-detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.user-grid-box {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.box-title {
    background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}

.box-title .page-title.page-sub-title {
    color: #fff;
    padding: 6px 0px;
}

.user-detail-cont.pb-0.bg-none:after {
    background: none;
}
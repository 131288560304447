.admin-main {
    /* display: flex; */
    height: 100vh;
    /* overflow: hidden; */
    background-color: var(--background);
}

.admin-main .btn {
    padding: 0;
    min-width: unset;
}

.admin-main .admin-main-left {
    width: 100%;
    max-width: 250px;
    transition: 0.5s all ease-in-out;
}

.admin-main .admin-main-left .admin-main-sidebar {
    background-image: linear-gradient(96.5deg, rgba(39, 103, 187, 1) 10.4%, rgba(16, 72, 144, 1) 87.7%);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 250px;
    /* transition: 0.5s all ease-in-out; */
    z-index: 9;
}

.admin-main .admin-main-left.deactive {
    max-width: 0;
}

.admin-main .admin-main-left.deactive .admin-main-sidebar {
    left: -250px;
}

.admin-main .company-logo {
    width: 100%;
    max-width: 100px;
    height: auto;
}

.admin-main .admin-sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 66px;
    padding: 8px 16px;
    box-shadow: var(--shadow-md);

}

.admin-main .admin-sidebar-logo button {
    display: none;
}

.admin-main .admin-sidebar-list {
    height: calc(100vh - 66px - 16px);
    overflow: auto;
    padding: 8px 0px 8px 8px;
    /* margin: 8px; */
}

.admin-main .admin-sidebar-list-inner {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0;
}

.admin-main .admin-sidebar-list-inner .admin-sidebar-listitem {
    padding: 0;
}

.admin-main .admin-sidebar-list .sidebar-link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-family: "poppins-medium";
    line-height: 18px;
    padding: 10px 10px 10px 16px;
    width: 100%;
    text-decoration: none;
}

.admin-main .admin-sidebar-list .sidebar-link svg {
    width: 20px;
    height: 20px;
}

.admin-main .admin-sidebar-list .sidebar-link svg path {
    fill: #fff;
}

.admin-main .admin-sidebar-list .sidebar-link.active {
    background: linear-gradient(45deg, #3ac7b3, transparent);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}

.admin-main .admin-main-right {
    padding-left: 250px;
}

.admin-main-right.deactive{
    padding-left: 0px;
}

.admin-main .admin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    height: 66px;
    padding: 10px 16px;
    background-color: var(--background);
    position: sticky;
    left: 0;
    top: 0;
    box-shadow: var(--shadow-md);
    z-index: 3;
}

.admin-main .nav-menu-btn {
    z-index: 1;

}

.admin-main .nav-menu-btn .line {
    fill: none;
    stroke: black;
    stroke-width: 8;
    transition: stroke-dasharray 1000ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 1000ms cubic-bezier(0.4, 0, 0.2, 1);
}

.admin-main .nav-menu-btn .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 8;
}

.admin-main .nav-menu-btn .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 8;
}

.admin-main .nav-menu-btn .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 8;
}

.admin-main .active .nav-menu-btn .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 8;
}

.admin-main .active .nav-menu-btn .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 8;
}

.admin-main .active .nav-menu-btn .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 8;
}

.admin-main .admin-header .admin-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.admin-main .admin-header .admin-header-right {
    display: flex;
    align-items: center;
    gap: 20px;
}


.admin-main .admin-header .admin-header-left .company-logo {
    opacity: 0;
    transition: 0.3s all ease-in-out;
}

.admin-main .deactive .admin-header .admin-header-left .company-logo {
    opacity: 1;
}

.admin-main .admin-header .admin-header-right .profile-drop-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.admin-main .admin-header .admin-header-right .profile-drop-btn .user-profile-btn {
    display: flex;
    flex-direction: column;
    text-align: end;
    margin-right: 5px;
    text-transform: none;
}

.admin-main .admin-header .admin-header-right .profile-drop-btn .head-user-title {
    color: var(--primary);
    font-family: "poppins-semibold";
    text-transform: capitalize;
    font-size: 13px;
}

.admin-main .admin-header .admin-header-right .profile-drop-btn img {
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--shadow-sm);
    object-fit: cover;
    height: 30px;
    width: 30px;
}

.admin-main .admin-content-main {
    height: calc(100vh - 66px);
    overflow: auto;
}

.profile-drop-menu a {
    text-decoration: none;

}

.profile-drop-menu .profile-drop-menuitem {
    font-size: 14px;
    line-height: 18px;
    color: #727272;
}


.cms-dropdown {
    display: flex;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    text-decoration: none;
    flex-direction: column;
    justify-content: start;
}

.cms-dropdown .cms-text img {
    margin-right: 10px;
}

.admin-content-main {
    padding: 20px 16px;
    background-image: url('../../../assets/images/png/background-dashboard.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.admin-main .form-group .form-input .css-10edkuc {
    background: transparent;
    border-radius: 0;
    border: 1px solid #fff;
}

.admin-main .form-group .css-1jg2gny{
    border: 1px solid #fff;
    border-radius: 0 !important;
}

.admin-main .form-group .form-input .css-vubbuv {
    fill: #fff;
}

.admin-tabs-main .css-k008qs {
    display: flex;
    justify-content: start;
}
